<template>
  <v-card :class="{
        'ma-3': $vuetify.breakpoint.xs, 
        'ml-3': $vuetify.breakpoint.sm,
        'mt-3 ml-3': $vuetify.breakpoint.mdAndUp, 
        'pa-2' : true, 
        'body-2' : true, 
        'd-flex align-center justify-start' : true }" elevation="2">
        <v-container>
            <v-row no-gutters class="pb-4 d-flex justify-end">
                <Downloadreport/>
            </v-row>
            <v-row no-gutters class="pb-2 pt-2" style="border-bottom: 1px solid lightgray">
                <v-col>{{ $t('sidebar.dayOfWeek') }}</v-col>
                <v-col class="d-flex justify-end">{{ dayInfo[0] }}</v-col>
            </v-row>
            <v-row no-gutters class="pb-2 pt-2" style="border-bottom: 1px solid lightgray">
                <v-col>{{ $t('sidebar.currentDate') }}</v-col>
                <v-col class="d-flex justify-end text-right">{{ dayInfo[1] }} {{ dayInfo[2] }}</v-col>
            </v-row>
            <v-row no-gutters class="pb-2 pt-2" style="border-bottom: 1px solid lightgray">
                <v-col>{{ $t('sidebar.institutionName') }}</v-col>
                <v-col class="d-flex justify-end">ABC</v-col>
            </v-row>
            <v-row no-gutters class="pb-2 pt-2" style="border-bottom: 1px solid lightgray">
                <v-col>{{ $t('sidebar.classGroupName') }} </v-col>
                <v-col class="d-flex justify-end">XYZ</v-col>
            </v-row>
            <v-row v-if="$vuetify.breakpoint.xs" no-gutters class="pb-2 pt-2" style="border-bottom: 1px solid lightgray">
                <v-col>{{ $t('dashboard.present') }}</v-col>
                <v-col class="d-flex justify-end"><v-chip small text-color="white" color="green">{{ presentStudents.length }}</v-chip></v-col>
            </v-row>
            <v-row v-if="$vuetify.breakpoint.xs" no-gutters class="pb-2 pt-2" style="border-bottom: 1px solid lightgray">
                <v-col>{{ $t('dashboard.ill') }}</v-col>
                <v-col class="d-flex justify-end"><v-chip small text-color="white" color="warning">{{ illStudents.length }}</v-chip></v-col>
            </v-row>
            <v-row v-if="$vuetify.breakpoint.xs" no-gutters class="pb-2 pt-2" style="border-bottom: 1px solid lightgray">
                <v-col>{{ $t('dashboard.absent') }}</v-col>
                <v-col class="d-flex justify-end"><v-chip small text-color="white" color="error">{{ absentStudents.length }}</v-chip></v-col>
            </v-row>
            <v-row v-if="$vuetify.breakpoint.xs" no-gutters class="pb-2 pt-2" style="border-bottom: 1px solid lightgray">
                <v-col>{{ $t('dashboard.unExcused') }}</v-col>
                <v-col class="d-flex justify-end"><v-chip small text-color="white" color="info">{{ unExcusedStudents.length }}</v-chip></v-col>
            </v-row>
            <v-row v-if="$vuetify.breakpoint.xs" no-gutters class="pb-2 pt-2" style="border-bottom: 1px solid lightgray">
                <v-col>{{ $t('dashboard.loggedOut') }}</v-col>
                <v-col class="d-flex justify-end"><v-chip small text-color="white" color="secondary">{{ loggedOutStudents.length }}</v-chip></v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import Downloadreport from '../components/Downloadreport.vue'

export default {
    name: 'Classinfo',
    components: { Downloadreport },
    computed: {
        ...mapGetters({
            dayInfo: 'dashboardOnline/dayInfo',
            students: 'dashboardOnline/getStudents',
            presentStudents : 'dashboardOnline/presentStudents',
            absentStudents: 'dashboardOnline/absentStudents',
            illStudents: 'dashboardOnline/illStudents',
            unExcusedStudents: 'dashboardOnline/unExcusedStudents',
            loggedOutStudents: 'dashboardOnline/loggedOutStudents',
        })
    },
}
</script>
